<script setup>
import { computed, defineProps } from "vue";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import i18n from "@/core/plugins/vue-i18n";
import { stateMachineStates } from "@/components/ExternalApps/SalesChannelManagementApp/utility/stateMachineStates";

const { selectedFeature } = useFeatures();

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
});

const salesOrderStatesArray = computed(
  () => stateMachineStates?.salesOrder?.map(state => state.value) ?? []
);

const badgeType = computed(() => {
  const {
    data: {
      item: { state }
    }
  } = props;

  if (
    selectedFeature.value === "tracking" &&
    salesOrderStatesArray.value.includes(state)
  ) {
    return "default";
  }

  if (
    (state === "imported" && selectedFeature.value !== "tracking") ||
    state === "stateImported" ||
    state === "statePartiallyImported"
  ) {
    return "success";
  }

  if (state.includes("Error")) {
    return "error";
  }

  return "default";
});

const item = computed(() => {
  const { data } = props;
  const state = data?.item?.state ?? "";
  const entity = data?.field?.entity ?? "default";

  let translateString = JSON.parse(JSON.stringify(state));

  if (
    selectedFeature.value === "tracking" &&
    salesOrderStatesArray.value.includes(translateString)
  ) {
    translateString = "noStateUpdate";
  } else if (state.includes("Error")) {
    return (
      data?.item?.comment ?? i18n.t("salesChannelManagementApp.reporting.error")
    );
  }

  return i18n.t(
    "salesChannelManagementApp.reporting.states." +
      entity +
      "." +
      translateString
  );
});
</script>

<template>
  <span class="state-badge" :class="'state-badge-' + badgeType">
    {{ item }}
  </span>
</template>

<style lang="scss" scoped>
.state-badge {
  padding: 6px 12px;
  border-radius: 6px;
  position: relative;
  white-space: nowrap;

  @each $type, $color in $connect-status-types {
    &-#{$type} {
      background-color: rgba($color, 0.3);
      color: darken($color, 30%);
    }
  }
}
</style>
